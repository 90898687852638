import React, { useContext, useState } from "react";
import { CheckboxItem } from "../components/CheckboxItem";
import "./iuvivoStyles.css";
import { ToolButton } from "../components/ToolButton";
import { IuvivoContext } from "./IuvivoContext";

export const LiveRoomMembers = ({ handleClose }) => {
  const { contacts, saveLiveRoomMembers, userLiveRoom, user } =
    useContext(IuvivoContext);
  const [members, setMembers] = useState(
    (userLiveRoom && userLiveRoom.members) || [],
  );

  const handleChange = (contact) => {
    const tmpMembers = [...members];
    if (!members.some((c) => c.userId === contact.userId)) {
      tmpMembers.push({ userId: contact.userId, userName: contact.userName });
      setMembers(tmpMembers);
    } else {
      const contactIndex = members.findIndex(
        (c) => c.userId === contact.userId,
      );
      tmpMembers.splice(contactIndex, 1);
      setMembers(tmpMembers);
    }
  };

  console.log("excalidraw LiveRoomMembers", contacts, userLiveRoom);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: 10,
        border: "1px solid orange",
        borderRadius: 4,
      }}
    >
      {userLiveRoom &&
        user &&
        user.userId === userLiveRoom.liveRoomOwner.userId && (
          <div>
            <div style={{ fontWeight: "bold", marginBottom: 15 }}>
              Lade andere zu deiner aktuellen Sitzung ein:{" "}
            </div>
            {contacts.map((contact) => (
              <div key={contact.userId} style={{ marginBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {contact.userName}{" "}
                  <CheckboxItem
                    checked={members.some((c) => c.userId === contact.userId)}
                    onChange={() => handleChange(contact)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#e7e7e7",
                  }}
                />
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ToolButton
                type="button"
                title={"Speichern"}
                aria-label={"Speichern"}
                label={"Speichern"}
                onClick={() => {
                  saveLiveRoomMembers(members);
                  handleClose();
                }}
                className="confirm-dialog"
              />
            </div>
          </div>
        )}
    </div>
  );
};

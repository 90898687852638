import React, { useContext, useState } from "react";
import "./iuvivoStyles.css";
import { ToolButton } from "../components/ToolButton";
import { IuvivoContext } from "./IuvivoContext";
import { load } from "../components/icons";
import { t } from "../i18n";
import { CollabContext } from "../excalidraw-app/collab/CollabWrapper";

export const LiveRooms = ({ handleClose }) => {
  const { user, liveRooms } = useContext(IuvivoContext);
  const collabAPI = useContext(CollabContext)?.api;

  console.log("excalidraw liverooms", liveRooms);

  const openLiveRoom = (roomData) => {
    collabAPI?.initializeSocketClient(roomData);
    handleClose();
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: 10,
      }}
    >
      {liveRooms &&
        liveRooms.length > 0 &&
        liveRooms[0].liveRoomOwner.userId !== user.userId && (
          <div className="Dialog__title">Einladungen zu Live-Sitzungen</div>
        )}
      {liveRooms &&
        liveRooms.map((item) => {
          return (
            item.liveRoomOwner.userId !== user.userId && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 10,
                  margin: 5,
                  border: "1px solid orange",
                }}
              >
                {item.liveRoomOwner.userName}
                <ToolButton
                  type="button"
                  icon={load}
                  title={"Öffnen"}
                  aria-label={t("buttons.load")}
                  onClick={() => openLiveRoom(item.roomData)}
                  data-testid="load-button"
                />
              </div>
            )
          );
        })}
      <div className="Dialog__title">Eigene Sitzung starten</div>
    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import IuvivoScenesDialog from "./IuvivoScenesDialog";
import { t } from "../i18n";
import { load, save } from "../components/icons";
import { useIsMobile } from "../components/App";
import { ToolButton } from "../components/ToolButton";
import { serializeAsJSON } from "../data/json";
import { IuvivoContext } from "./IuvivoContext";

export const IUVIVO_IFRAME_ORIGIN = "https://app.iuvivo.de";

export const IuvivoComponent = ({
  actionManager,
  elements,
  appState,
  files,
}) => {
  const {
    user,
    setUser,
    setUserLiveRoom,
    contacts,
    setContacts,
    liveRooms,
    setLiveRooms,
  } = useContext(IuvivoContext);
  const [scenes, setScenes] = useState([]);
  const [showList, setShowList] = useState();
  const [sceneId, setSceneId] = useState();
  const [scene, setScene] = useState();

  console.log("excalidraw IuvivoComponent", scenes, user, contacts, sceneId);

  useEffect(() => {
    window.addEventListener("message", (event) =>
      handleIuvivoIframeEvent(event),
    );
    window.top &&
      window.top.postMessage({ type: "loaded" }, IUVIVO_IFRAME_ORIGIN);
  }, []);

  useEffect(() => {
    if (user && liveRooms) {
      setUserLiveRoom(
        liveRooms.find((scene) => scene.liveRoomOwner.userId === user.userId),
      );
    }
  }, [user, liveRooms]);

  const handleIuvivoIframeEvent = (event) => {
    console.log(
      "excalidraw handleIuvivoIframeEvent",
      event,
      event.data,
      event.origin,
    );
    if (event.origin === IUVIVO_IFRAME_ORIGIN) {
      if (event.data.type === "contacts") {
        console.log(
          "excalidraw handleIuvivoIframeEvent contacts",
          event,
          scenes,
        );
        setContacts(event.data.contacts);
        setUser(event.data.user);
      }
      if (event.data.type === "iuvivoScenes") {
        console.log(
          "excalidraw handleIuvivoIframeEvent iuvivoScenes",
          event,
          event.data.scenes,
          user,
        );
        // window.localStorage.setItem('iuvivoScenes', JSON.stringify(event.data.scenes));
        const scenes = event.data.scenes.filter(
          (scene) => !scene.liveRoomOwner,
        );
        const allLiveRooms = event.data.scenes.filter(
          (scene) => scene.liveRoomOwner,
        );
        setScenes(scenes);
        setLiveRooms(allLiveRooms);

        console.log(
          "excalidraw scenes liverooms",
          scenes,
          allLiveRooms,
          liveRooms,
        );
      }
    }
  };

  const saveScene = (update = true) => {
    window.top &&
      window.top.postMessage(
        {
          type: "scene",
          scene: serializeAsJSON(elements, appState, files, "local"),
          sceneId: update && sceneId,
        },
        IUVIVO_IFRAME_ORIGIN,
      );
  };

  return (
    <div style={{ display: "flex" }}>
      <ToolButton
        type="button"
        icon={load}
        title={"Zeichnungen"}
        aria-label={"Zeichnungen"}
        showAriaLabel={useIsMobile()}
        onClick={() => setShowList(!showList)}
        data-testid="load-button"
      />
      <div style={{ marginRight: 5 }} />
      {showList && (
        <IuvivoScenesDialog
          onConfirm={() => setShowList(false)}
          onCancel={() => setShowList(false)}
          title={"Zeichnungen"}
          contacts={contacts}
          scenes={scenes}
          liveRooms={liveRooms}
          sceneId={sceneId}
          setSceneId={setSceneId}
          scene={scene}
          setScene={setScene}
          saveScene={saveScene}
          actionManager={actionManager}
          elements={elements}
          appState={appState}
          files={files}
        >
          <p className="clear-canvas__content" />
        </IuvivoScenesDialog>
      )}
    </div>
  );
};

export const iuvivoLibraryItems = JSON.parse(
  "[\n" +
    "    {\n" +
    '      "status": "unpublished",\n' +
    '      "elements": [\n' +
    "        {\n" +
    '          "id": "ZtsarM2KuOylmvFuShn0M",\n' +
    '          "type": "ellipse",\n' +
    '          "x": 505.4273681640625,\n' +
    '          "y": 405.7159881591797,\n' +
    '          "width": 95.78704833984375,\n' +
    '          "height": 89.39218139648438,\n' +
    '          "angle": 0,\n' +
    '          "strokeColor": "#000000",\n' +
    '          "backgroundColor": "#fab005",\n' +
    '          "fillStyle": "hachure",\n' +
    '          "strokeWidth": 1,\n' +
    '          "strokeStyle": "solid",\n' +
    '          "roughness": 1,\n' +
    '          "opacity": 100,\n' +
    '          "groupIds": [\n' +
    '            "ccz9Mi-A1lfv9LK8e7R12"\n' +
    "          ],\n" +
    '          "strokeSharpness": "sharp",\n' +
    '          "seed": 806894666,\n' +
    '          "version": 48,\n' +
    '          "versionNonce": 1547793942,\n' +
    '          "isDeleted": false,\n' +
    '          "boundElements": null,\n' +
    '          "updated": 1647271086868,\n' +
    '          "link": null\n' +
    "        },\n" +
    "        {\n" +
    '          "id": "belhWJGiv5PrSeC4qCc-d",\n' +
    '          "type": "ellipse",\n' +
    '          "x": 593.1356006857865,\n' +
    '          "y": 419.97877502441406,\n' +
    '          "width": 11.903454639464458,\n' +
    '          "height": 11.108764648437504,\n' +
    '          "angle": 0,\n' +
    '          "strokeColor": "#000000",\n' +
    '          "backgroundColor": "transparent",\n' +
    '          "fillStyle": "hachure",\n' +
    '          "strokeWidth": 1,\n' +
    '          "strokeStyle": "solid",\n' +
    '          "roughness": 1,\n' +
    '          "opacity": 100,\n' +
    '          "groupIds": [\n' +
    '            "ccz9Mi-A1lfv9LK8e7R12"\n' +
    "          ],\n" +
    '          "strokeSharpness": "sharp",\n' +
    '          "seed": 103611978,\n' +
    '          "version": 253,\n' +
    '          "versionNonce": 457776714,\n' +
    '          "isDeleted": false,\n' +
    '          "boundElements": null,\n' +
    '          "updated": 1647271086868,\n' +
    '          "link": null\n' +
    "        },\n" +
    "        {\n" +
    '          "type": "ellipse",\n' +
    '          "version": 386,\n' +
    '          "versionNonce": 1880507222,\n' +
    '          "isDeleted": false,\n' +
    '          "id": "YYzgqaL2wqTVrHGEFWFtc",\n' +
    '          "fillStyle": "hachure",\n' +
    '          "strokeWidth": 1,\n' +
    '          "strokeStyle": "solid",\n' +
    '          "roughness": 1,\n' +
    '          "opacity": 100,\n' +
    '          "angle": 0,\n' +
    '          "x": 572.1044649316201,\n' +
    '          "y": 424.825201181265,\n' +
    '          "strokeColor": "#000000",\n' +
    '          "backgroundColor": "transparent",\n' +
    '          "width": 3.8427856941519782,\n' +
    '          "height": 2.1671752929687513,\n' +
    '          "seed": 284969558,\n' +
    '          "groupIds": [\n' +
    '            "ccz9Mi-A1lfv9LK8e7R12"\n' +
    "          ],\n" +
    '          "strokeSharpness": "sharp",\n' +
    '          "boundElements": [],\n' +
    '          "updated": 1647271086868,\n' +
    '          "link": null\n' +
    "        },\n" +
    "        {\n" +
    '          "id": "x4KsxbL1zKnlNzBhsre8a",\n' +
    '          "type": "ellipse",\n' +
    '          "x": 582.4824276370603,\n' +
    '          "y": 441.55113220214827,\n' +
    '          "width": 3.074740323153378,\n' +
    '          "height": 3.074740323153378,\n' +
    '          "angle": 0,\n' +
    '          "strokeColor": "#000000",\n' +
    '          "backgroundColor": "transparent",\n' +
    '          "fillStyle": "hachure",\n' +
    '          "strokeWidth": 1,\n' +
    '          "strokeStyle": "solid",\n' +
    '          "roughness": 1,\n' +
    '          "opacity": 100,\n' +
    '          "groupIds": [\n' +
    '            "ccz9Mi-A1lfv9LK8e7R12"\n' +
    "          ],\n" +
    '          "strokeSharpness": "sharp",\n' +
    '          "seed": 634480278,\n' +
    '          "version": 173,\n' +
    '          "versionNonce": 1977795850,\n' +
    '          "isDeleted": false,\n' +
    '          "boundElements": null,\n' +
    '          "updated": 1647271086868,\n' +
    '          "link": null\n' +
    "        }\n" +
    "      ],\n" +
    '      "id": "FqxhuOEnjpK9-afZq5GqA",\n' +
    '      "created": 1647271092024\n' +
    "    }\n" +
    " ,{\n" +
  "      \"status\": \"unpublished\",\n" +
  "      \"elements\": [\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 390,\n" +
  "          \"versionNonce\": 1295773621,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"C174W27edeBmWhuFAm43H\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 3.226658006500106,\n" +
  "          \"x\": 848.483083233248,\n" +
  "          \"y\": 361.8994957056353,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"#fab005\",\n" +
  "          \"width\": 95.78704833984375,\n" +
  "          \"height\": 89.39218139648438,\n" +
  "          \"seed\": 597163899,\n" +
  "          \"groupIds\": [\n" +
  "            \"dBZLn2cnf0IY5t0UbDt95\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 599,\n" +
  "          \"versionNonce\": 679727643,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"Z2TkloHWPK_l-v9zeC2Y6\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 3.226658006500106,\n" +
  "          \"x\": 842.7101473432626,\n" +
  "          \"y\": 421.9417219931186,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 11.903454639464458,\n" +
  "          \"height\": 11.108764648437504,\n" +
  "          \"seed\": 25561525,\n" +
  "          \"groupIds\": [\n" +
  "            \"dBZLn2cnf0IY5t0UbDt95\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 730,\n" +
  "          \"versionNonce\": 1877881109,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"S0bfWvcbMHdktsDICqmH8\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 3.226658006500106,\n" +
  "          \"x\": 871.7432475777025,\n" +
  "          \"y\": 428.1675361007761,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 3.8427856941519782,\n" +
  "          \"height\": 2.1671752929687513,\n" +
  "          \"seed\": 1224759323,\n" +
  "          \"groupIds\": [\n" +
  "            \"dBZLn2cnf0IY5t0UbDt95\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 518,\n" +
  "          \"versionNonce\": 1914230459,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"eprgtoyfT06_d6Kps076t\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 3.226658006500106,\n" +
  "          \"x\": 863.6291036485891,\n" +
  "          \"y\": 409.7470466394835,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 3.074740323153378,\n" +
  "          \"height\": 3.074740323153378,\n" +
  "          \"seed\": 132451093,\n" +
  "          \"groupIds\": [\n" +
  "            \"dBZLn2cnf0IY5t0UbDt95\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 695,\n" +
  "          \"versionNonce\": 2039563893,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"RMnVaWLWS63PJ6nykt3Ba\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 4.5233670818766285,\n" +
  "          \"x\": 511.7350169498228,\n" +
  "          \"y\": 534.9566539419134,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"#15aabf\",\n" +
  "          \"width\": 95.78704833984375,\n" +
  "          \"height\": 89.39218139648438,\n" +
  "          \"seed\": 487890107,\n" +
  "          \"groupIds\": [\n" +
  "            \"Wr2Pu8bDk2sG1ccnEocAc\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 760,\n" +
  "          \"versionNonce\": 400836443,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"Aad0dGcNrmAEoYH74sEtx\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 4.652922556779556,\n" +
  "          \"x\": 526.1219059331399,\n" +
  "          \"y\": 529.8914123876132,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 11.903454639464458,\n" +
  "          \"height\": 11.108764648437504,\n" +
  "          \"seed\": 1637671029,\n" +
  "          \"groupIds\": [\n" +
  "            \"Wr2Pu8bDk2sG1ccnEocAc\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 892,\n" +
  "          \"versionNonce\": 2095755221,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"fE69wYmTvyWokbXBEh4ya\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 4.652922556779556,\n" +
  "          \"x\": 532.0166457197611,\n" +
  "          \"y\": 559.3570541695663,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 3.8427856941519782,\n" +
  "          \"height\": 2.1671752929687513,\n" +
  "          \"seed\": 997674331,\n" +
  "          \"groupIds\": [\n" +
  "            \"Wr2Pu8bDk2sG1ccnEocAc\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 680,\n" +
  "          \"versionNonce\": 540582907,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"VYubLfuguHmoRikI_QnK1\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 4.652922556779556,\n" +
  "          \"x\": 548.9560612988032,\n" +
  "          \"y\": 547.9059828776533,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 3.074740323153378,\n" +
  "          \"height\": 3.074740323153378,\n" +
  "          \"seed\": 1416825301,\n" +
  "          \"groupIds\": [\n" +
  "            \"Wr2Pu8bDk2sG1ccnEocAc\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 862,\n" +
  "          \"versionNonce\": 403696949,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"JAfYERDUprDnobt4AXBOi\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 7.733481011189516,\n" +
  "          \"x\": 474.46224359493937,\n" +
  "          \"y\": 461.36636411801743,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"#15aabf\",\n" +
  "          \"width\": 50.44033014609562,\n" +
  "          \"height\": 47.072868621245235,\n" +
  "          \"seed\": 662366715,\n" +
  "          \"groupIds\": [\n" +
  "            \"WXnrUlrdR_pkFkaqjWDqn\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 930,\n" +
  "          \"versionNonce\": 588803227,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"v5-oY_TFslanDjS4iwKp2\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 7.863036486092442,\n" +
  "          \"x\": 509.43048932298905,\n" +
  "          \"y\": 506.1956290798069,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 6.268218848997671,\n" +
  "          \"height\": 5.849744470614271,\n" +
  "          \"seed\": 1367148341,\n" +
  "          \"groupIds\": [\n" +
  "            \"WXnrUlrdR_pkFkaqjWDqn\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 1064,\n" +
  "          \"versionNonce\": 174489237,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"ran19gtnTkqsFqJb5gx_Z\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 7.863036486092442,\n" +
  "          \"x\": 511.47451685933254,\n" +
  "          \"y\": 495.35157209775167,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 2.023565632861164,\n" +
  "          \"height\": 1.1412089541998656,\n" +
  "          \"seed\": 1445065371,\n" +
  "          \"groupIds\": [\n" +
  "            \"WXnrUlrdR_pkFkaqjWDqn\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 852,\n" +
  "          \"versionNonce\": 339091771,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"DuhQXG6Xav1in02PAJcjE\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 7.863036486092442,\n" +
  "          \"x\": 502.5828227388395,\n" +
  "          \"y\": 500.2931759811432,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 1.6191219971944486,\n" +
  "          \"height\": 1.6191219971944486,\n" +
  "          \"seed\": 186669205,\n" +
  "          \"groupIds\": [\n" +
  "            \"WXnrUlrdR_pkFkaqjWDqn\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 357,\n" +
  "          \"versionNonce\": 178512885,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"0HxOZ3cPO9duZQFo0ZhEb\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 5.2086317446241015,\n" +
  "          \"x\": 570.7988878510405,\n" +
  "          \"y\": 199.13186619123977,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"#fab005\",\n" +
  "          \"width\": 76.28659987548696,\n" +
  "          \"height\": 71.19360803347699,\n" +
  "          \"seed\": 2015291195,\n" +
  "          \"groupIds\": [\n" +
  "            \"rf8TzzemV2QE1OXLM4iAP\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 562,\n" +
  "          \"versionNonce\": 1291076059,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"gMHgy1cq5_I5WkNhmNDTY\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 5.2086317446241015,\n" +
  "          \"x\": 604.1324900431005,\n" +
  "          \"y\": 188.81844504613363,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 9.480134286997433,\n" +
  "          \"height\": 8.847228289566152,\n" +
  "          \"seed\": 1259503093,\n" +
  "          \"groupIds\": [\n" +
  "            \"rf8TzzemV2QE1OXLM4iAP\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 695,\n" +
  "          \"versionNonce\": 1556979029,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"nSHbgiqsX3BUDO1t3QjUI\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 5.2086317446241015,\n" +
  "          \"x\": 598.1022199941351,\n" +
  "          \"y\": 210.07337948178477,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 3.060466521704862,\n" +
  "          \"height\": 1.7259790055142423,\n" +
  "          \"seed\": 64243675,\n" +
  "          \"groupIds\": [\n" +
  "            \"rf8TzzemV2QE1OXLM4iAP\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        },\n" +
  "        {\n" +
  "          \"type\": \"ellipse\",\n" +
  "          \"version\": 482,\n" +
  "          \"versionNonce\": 1449479803,\n" +
  "          \"isDeleted\": false,\n" +
  "          \"id\": \"GxszJWd1Qf0OoTU3h-4mR\",\n" +
  "          \"fillStyle\": \"hachure\",\n" +
  "          \"strokeWidth\": 1,\n" +
  "          \"strokeStyle\": \"solid\",\n" +
  "          \"roughness\": 1,\n" +
  "          \"opacity\": 100,\n" +
  "          \"angle\": 5.2086317446241015,\n" +
  "          \"x\": 614.2295741472915,\n" +
  "          \"y\": 209.2271517020288,\n" +
  "          \"strokeColor\": \"#000000\",\n" +
  "          \"backgroundColor\": \"transparent\",\n" +
  "          \"width\": 2.4487807988531425,\n" +
  "          \"height\": 2.4487807988531425,\n" +
  "          \"seed\": 140048213,\n" +
  "          \"groupIds\": [\n" +
  "            \"rf8TzzemV2QE1OXLM4iAP\"\n" +
  "          ],\n" +
  "          \"strokeSharpness\": \"sharp\",\n" +
  "          \"boundElements\": [],\n" +
  "          \"updated\": 1647380190010,\n" +
  "          \"link\": null\n" +
  "        }\n" +
  "      ],\n" +
  "      \"id\": \"W-J4OZqFPEBwxVxUO24D3\",\n" +
  "      \"created\": 1647380209275\n" +
  "    } ]",
);


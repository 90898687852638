import React, { createContext, useState } from "react";
import { IUVIVO_IFRAME_ORIGIN } from "./IuvivoComponent";

export const IuvivoContext = createContext({});

export const setIuvivoLiveRoom = (roomData, members) => {
  window.top &&
    window.top.postMessage(
      { type: "saveLiveRoom", roomData, members },
      IUVIVO_IFRAME_ORIGIN,
    );
};

const IuvivoContextProvider = (props) => {
  const { children } = props;
  const [contacts, setContacts] = useState([]);
  const [liveRooms, setLiveRooms] = useState([]);
  const [user, setUser] = useState();
  const [userLiveRoom, setUserLiveRoom] = useState();

  const saveLiveRoomMembers = (members) => {
    setIuvivoLiveRoom(userLiveRoom.roomData, members);
  };

  return (
    <IuvivoContext.Provider
      value={{
        contacts,
        setContacts,
        liveRooms,
        setLiveRooms,
        user,
        setUser,
        userLiveRoom,
        setUserLiveRoom,
        saveLiveRoomMembers,
      }}
    >
      {children}
    </IuvivoContext.Provider>
  );
};

export default IuvivoContextProvider;

import React, { useState } from "react";
import { CheckboxItem } from "../components/CheckboxItem";
import "./iuvivoStyles.css";
import { ToolButton } from "../components/ToolButton";

export const SceneMembers = ({
  contacts,
  scene,
  saveMembers,
  toggleMembersBox,
}) => {
  const [members, setMembers] = useState(scene.members || []);

  const handleChange = (contact) => {
    const tmpMembers = [...members];
    if (!members.some((c) => c.userId === contact.userId)) {
      tmpMembers.push({ userId: contact.userId, userName: contact.userName });
      setMembers(tmpMembers);
    } else {
      const contactIndex = members.findIndex(
        (c) => c.userId === contact.userId,
      );
      tmpMembers.splice(contactIndex, 1);
      setMembers(tmpMembers);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: 10,
      }}
    >
      {contacts.map((contact) => (
        <div key={contact.userId} style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {contact.userName}{" "}
            <CheckboxItem
              checked={members.some((c) => c.userId === contact.userId)}
              onChange={() => handleChange(contact)}
            />
          </div>
          <div
            style={{ width: "100%", height: 1, backgroundColor: "#e7e7e7" }}
          />
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ToolButton
          type="button"
          title={"Abbrechen"}
          aria-label={"Abbrechen"}
          label={"Abbrechen"}
          onClick={(e) => toggleMembersBox(e)}
          className="confirm-dialog--cancel"
        />
        <ToolButton
          type="button"
          title={"Speichern"}
          aria-label={"Speichern"}
          label={"Speichern"}
          onClick={(e) => saveMembers(e, members, scene)}
          className="confirm-dialog--cancel"
        />
      </div>
    </div>
  );
};

import { t } from "../i18n";
import { Dialog, DialogProps } from "../components/Dialog";
import { ToolButton } from "../components/ToolButton";
import { ActionManager } from "../../src/actions/manager";

import "../components/ConfirmDialog.scss";
import React, { useContext, useState } from "react";
import { AppState, BinaryFiles } from "../types";
import { ExcalidrawElement } from "../element/types";
import { serializeAsJSON } from "../data/json";
import { SceneMembers } from "./SceneMembers";
import { users, createIcon, close, trash } from "../components/icons";
import { IUVIVO_IFRAME_ORIGIN } from "./IuvivoComponent";
import { IuvivoContext } from "./IuvivoContext";

interface Props extends Omit<DialogProps, "onCloseRequest"> {
  onConfirm: any;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  scenes?: any;
  actionManager: ActionManager;
  elements: readonly ExcalidrawElement[];
  appState: AppState;
  files: BinaryFiles;
  sceneId: any;
  setSceneId: any;
  scene: any;
  setScene: any;
  contacts: any;
  liveRooms: any;
  saveScene: any;
}

const IuvivoScenesDialog = (props: Props) => {
  const {
    liveRooms,
    contacts,
    sceneId,
    setSceneId,
    scene,
    setScene,
    saveScene,
    files,
    elements,
    appState,
    actionManager,
    scenes,
    onConfirm,
    onCancel,
    children,
    confirmText = t("buttons.confirm"),
    cancelText = t("buttons.cancel"),
    className = "",
    ...rest
  } = props;

  // @ts-ignore
  const { user } = useContext(IuvivoContext);
  const [title, setTitle] = useState("");
  const [rename, setRename] = useState();
  const [membersBox, setMembersBox] = useState();
  console.log("excalidraw IuvivoScenesDialog sceneId", sceneId);

  const saveRename = () => {
    window.top &&
      window.top.postMessage(
        {
          type: "scene",
          scene: serializeAsJSON(elements, appState, files, "local"),
          sceneId,
          title,
        },
        IUVIVO_IFRAME_ORIGIN,
      );
    // @ts-ignore
    setRename(false);
    // @ts-ignore
    setTitle(false);
  };

  const saveMembers = (e: any, members: any, scene: any) => {
    e.stopPropagation();
    window.top &&
      window.top.postMessage(
        {
          type: "scene",
          scene: serializeAsJSON(elements, appState, files, "local"),
          sceneId: scene.id,
          members,
        },
        IUVIVO_IFRAME_ORIGIN,
      );
    // @ts-ignore
    setMembersBox(false);
  };

  const deleteScene = (e: any, scene: any) => {
    e.stopPropagation();
    window.top &&
      window.top.postMessage(
        {
          type: "deleteScene",
          sceneId: scene.id,
        },
        IUVIVO_IFRAME_ORIGIN,
      );
  };

  const handleSceneSelect = (id: any) => {
    const scene = scenes.find((scene: { id: string }) => scene.id === id).scene;
    console.log("handleSceneSelect", scene.id, id);
    if (sceneId !== id) {
      setScene(scene);
      setSceneId(id);
      // @ts-ignore
      setRename(false);
      // @ts-ignore
      setTitle(false);
      // @ts-ignore
      setMembersBox(false);
    }
  };

  const toggleMembersBox = (e: any, id: any) => {
    e.stopPropagation();
    // @ts-ignore
    membersBox ? setMembersBox(false) : setMembersBox(id);
  };

  const toggleRename = (e: any, id: any) => {
    e.stopPropagation();
    // @ts-ignore
    rename ? setRename(false) : setRename(id);
  };

  return (
    <Dialog
      onCloseRequest={onCancel}
      small={true}
      {...rest}
      className={`confirm-dialog ${className}`}
    >
      {children}
      {scenes &&
        scenes.map((item: any) => {
          console.log("excalidraw item", item);
          return (
            <div
              key={item.id}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 10,
                border: "1px solid orange",
                borderRadius: 4,
                margin: 5,
                cursor: "pointer",
                // @ts-ignore
                backgroundColor: item.id === sceneId && "#FFF3E7FF",
              }}
              onClick={() => handleSceneSelect(item.id)}
            >
              {rename === item.id ? (
                <div style={{ display: "flex", width: "100%" }}>
                  <input
                    style={{
                      border: "1px solid orange",
                      borderRadius: 4,
                      padding: 5,
                    }}
                    defaultValue={item.title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <ToolButton
                    className={"button"}
                    type="button"
                    icon={close}
                    title={"Abbrechen"}
                    aria-label={"Abbrechen"}
                    onClick={(e) => toggleRename(e, item.id)}
                    data-testid="save-button"
                  />
                  <div style={{ marginLeft: "auto" }} />
                  <ToolButton
                    className={"button"}
                    type="button"
                    title={"OK"}
                    aria-label={"OK"}
                    label={"OK"}
                    onClick={saveRename}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {sceneId === item.id && (
                      <ToolButton
                        type="button"
                        icon={trash}
                        title={"Szene löschen"}
                        aria-label={"Szene löschen"}
                        label={"Szene löschen"}
                        onClick={(e) => deleteScene(e, item)}
                        className="confirm-dialog--cancel"
                      />
                    )}
                    <div style={{ marginLeft: 15 }}>
                      {item.title || "Unbenannte Zeichnung"}
                      <div style={{ fontSize: 11 }}>
                        von {item.owner.userName}
                      </div>
                    </div>
                    <div style={{ marginLeft: "auto" }} />
                    {user &&
                      user.userId === item.owner.userId &&
                      sceneId === item.id && (
                        <ToolButton
                          className={"button"}
                          type="button"
                          icon={createIcon(
                            <path
                              fill={"#ff8000"}
                              d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                            />,
                            { width: 448, height: 512 },
                          )}
                          title={"Umbenennen"}
                          aria-label={"Umbenennen"}
                          onClick={(e) => toggleRename(e, item.id)}
                          data-testid="save-button"
                        />
                      )}
                    {user &&
                      user.userId === item.owner.userId &&
                      sceneId === item.id && (
                        <ToolButton
                          className={"button"}
                          type="button"
                          icon={users}
                          title={"Teilen"}
                          aria-label={"Teilen"}
                          onClick={(e) => toggleMembersBox(e, item.id)}
                          data-testid="save-button"
                        />
                      )}
                  </div>
                  {membersBox === item.id && (
                    <div
                      style={{
                        marginTop: 15,
                        backgroundColor: "white",
                        padding: 10,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 10,
                          padding: 10,
                        }}
                      >
                        Szene teilen mit...
                      </div>
                      <SceneMembers
                        contacts={contacts}
                        scene={scenes.find(
                          (scene: { id: string }) => scene.id === item.id,
                        )}
                        saveMembers={saveMembers}
                        toggleMembersBox={toggleMembersBox}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      <div style={{ width: "100%", textAlign: "end" }}>
        <ToolButton
          type="button"
          title={"Neu"}
          aria-label={"Neu"}
          label={"+"}
          onClick={() => {
            saveScene(false);
          }}
          className="confirm-dialog"
        />
      </div>
      {scene && (
        <div
          style={{
            display: "flex",
            padding: "0.2rem 0",
            justifyContent: "space-between",
            marginTop: 10,
            paddingTop: 10,
            borderTop: "1px solid orange",
          }}
        >
          <ToolButton
            type="button"
            // icon={save}
            title={"Speichern"}
            aria-label={"Speichern"}
            label={"Speichern"}
            onClick={saveScene}
            data-testid="save-button"
            className="confirm-dialog"
          />

          <ToolButton
            type="button"
            title={confirmText}
            aria-label={confirmText}
            label={"Öffnen"}
            onClick={() => {
              actionManager.loadIuvivoScene(scene);
              onConfirm();
            }}
            className="confirm-dialog"
          />
        </div>
      )}
    </Dialog>
  );
};
export default IuvivoScenesDialog;
